import React, { Component } from 'react';
import Topnav from '../../components/Topnav/Topnav';
import Bottomnav from '../../components/Bottomnav/Bottomnav';
import { Carousel } from 'antd';
import 'antd/dist/antd.css';
import style from "./Home.module.css";
import { Link } from 'react-router-dom';
import Swiper from 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';
import Lazyimg from 'react-lazyimg-component';
import 'velocity-animate';
import 'velocity-animate/velocity.ui';
import axios from "../../model/Axios";
// import { CSSTransition, TransitionGroup } from 'react-transition-group';//引入动画库
// import { url } from 'inspector';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            className: 'hidden',
            imgs: [],
            product: [],
            news: [],
            show: true,
            list: []
        };
    }
    onChange = (a, b, c) => {
        // console.log(a, b, c);
    }
    componentDidMount() {
        axios.get('/home/index')
            .then((res) => {

                let data = res.data.res;
                this.setState({
                    imgs: data.imgs,
                    product: data.product,
                    news: data.news
                })
                let myswiper = new Swiper('.swiper-container', {
                    observer: true,
                    observeParents: false,//修改swiper的父元素时，自动初始化swiper
                    autoplay: {   //滑动后继续播放
                        delay: 3000,
                        stopOnLastSlide: false,
                        disableOnInteraction: true,
                    },
                    onSlideChangeEnd: function (swiper) {
                        swiper.update();
                        myswiper.startAutoplay();
                        myswiper.reLoop();
                    },
                    pagination: {  //分页器
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    slidesPerView: 3,
                    spaceBetween: 10,
                    // effect: 'coverflow',
                    parallax: true,
                    keyboard: {
                        enabled: true,
                        onlyInViewport: true,
                    },
                    // loop: true,  //循环
                })

            })
            .catch((err) => {
                console.log(err);
            })
    }
    render() {
        return (

            <div>

                <Topnav />
                {/* 第一个轮播 */}
                <Carousel afterChange={this.onChange} autoplay={"true"} >


                    {
                        this.state.imgs.map((item, ind) => {
                            return <div ref="imgH" className={style.lunbo_img} key={ind}>
                                <img src={`http://www.qpccx.com:8877${item.value}`} alt="" ref="imgH" />
                            </div>
                        })
                    }
                </Carousel>




                {/* 关于我们 */}

                <div className={style.aboutus}>


                    <div className={style.center}>
                        <div className={style.imgtitle}><img src={require("../img/title.png")} alt="" data-aos="zoom-in-up" /></div>
                        <p className={style.contents} data-aos="zoom-in-up">从2002年初在温江柳春路开出第一家店 至今全国七婆店门数量已达1100多家 涵盖全国各省、市、自治区，并全面开启海外渠道</p>
                        <p className={style.more} data-aos="zoom-in-up">
                            <Link to={{ pathname: "/aboutus.html" }}>
                                查看更多
                            </Link>
                        </p>
                        <div className={style.imgtwo} data-aos="zoom-in-up">
                            <video className={style.img_aboutus} muted="muted" controls="controls" object-fit="fill" preload="auto" autoplay = {"true"}>
                                <source src="http://www.qpccx.com/videos.mp4" type="video/mp4"/>
                            </video>
                        </div>
                        </div>
                    </div>
                    <div className={style.keepspace}></div>

                    {/* 菜品展示 */}
                    <div className={style.menushow}>
                        <div className={style.centermenu}>
                            <div className={style.imgtitle} >
                                <img src={require("../img/menutitle.png")} alt="" data-aos="zoom-in-up" />
                                {/* <CSSTransition
                                in={this.state.show}
                                timeout={5000}//动画执行持续时间毫秒
                                className='fade'//动画类名
                                unmountOnExit//可选，当动画出场后在页面上移除包裹的dom节点
                                appear={true}//第一次展示的时候也要现实动画效果
                            > */}
                                <p data-aos="zoom-in-up">
                                    保证您的健康
                                    我们认真把控食品安全
                                    看不见的管理
                                    看得见的新鲜
                            </p>
                                {/* </CSSTransition> */}

                            </div>
                            <div className={style.menudetail}>
                                <div className="swiper-container" >
                                    <div className="swiper-wrapper">
                                        {
                                            this.state.product.map((item, ind) => {
                                                // console.log(item);
                                                return <div className={["swiper-slide", style.newsone].join(" ")} key={ind} data-aos="zoom-in-up">
                                                    <div className={style.newsimg}>
                                                        <img src={`http://www.qpccx.com:8877${item.img}`} alt="" className={style.newsimgone} data-aos="zoom-in-up" />
                                                    </div>
                                                    <h5>{item.name}</h5>
                                                    <h5>{item.en_name}</h5>
                                                </div>
                                            })
                                        }
                                    </div>
                                    {/* <div className="swiper-pagination"></div> */}
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* 新闻资讯 */}
                    <div className={style.news}>
                        <div className={style.center}>
                            <div className={style.imgtitle} data-aos="zoom-in-up">
                                <img src={require("../img/newsone.png")} alt="" />
                            </div>
                            <div className={style.newspart}>
                                {
                                    this.state.news.map((item, ind) => {
                                        console.log(item.id);
                                        return <div className={style.newsone} key={ind} data-aos="zoom-in-up">
                                            <div className={style.newsimg}>
                                                <img src={`http://www.qpccx.com:8877${item.img}`} alt="" className={style.newsimgone} />
                                            </div>
                                            <h5 >{item.title}</h5>
                                            <p >{item.remark}</p>
                                            <div className={style.more}> <Link to={{ pathname: "/Newsdetail.html", state: { id: item.id } }}>more</Link></div>
                                        </div>
                                    })
                                }
                            </div>
                            <div className={style.more} data-aos="zoom-in-up">
                                <Link to={{ pathname: "/news.html" }}>
                                    查看更多
                            </Link>
                            </div>
                        </div>
                    </div>

                    {/* 门店展示 */}
                    <div className={style.shopshow}>
                        <div className={style.center}>
                            <div className={style.imgtitle} data-aos="zoom-in-up">
                                <Lazyimg
                                    className="lazy"
                                    src={require("../img/shoptitle.png")}
                                />
                            </div>
                            <div className={style.imgtitle} data-aos="zoom-in-up">
                                <img src={require("../img/shopbig.png")} alt="" className={style.shoppic} />
                            </div>
                        </div>
                        <div className={style.more} data-aos="zoom-in-up">
                            <Link to={{ pathname: "/shopshow.html" }}>
                                查看更多
                        </Link>
                        </div>
                    </div>

                    {/* 合作加盟 */}
                    <div className={style.operation}>
                        <div className={style.center}>
                            <div className={style.top}>
                                <div className={style.imgtitle} data-aos="zoom-in-up">
                                    <img src={require("../img/cotitle.png")} alt="" />
                                </div>
                                <p data-aos="zoom-in-up">
                                    经过多年的发展，成都七婆已经成为一家集
                                    品牌管理、餐饮策划、经营诊断，火锅底料生产、人资输出、物流配套，为一体的综合性餐饮企业，
                                    旗下子品牌达17个
                                    无论您身在何处，七婆总能为您提供一款适合的产品
                            </p>
                                <div className={style.more} data-aos="zoom-in-up">   <Link to={{ pathname: "/cooperation.html" }}>查看更多 </Link></div>
                            </div>
                            <div className={style.bottomm}>
                                <div className={style.workerone} data-aos="zoom-in-up">
                                    <div className={style.coverbg} ></div>
                                    <Lazyimg
                                        className="lazy"
                                        src={require("../img/ziran.png")}
                                    />
                                </div>
                                <div className={style.workerone} data-aos="zoom-in-up">
                                    <div className={style.coverbg} ></div>
                                    <Lazyimg
                                        className="lazy"
                                        src={require("../img/ma.png")}
                                    />
                                </div>
                                <div className={style.workerone} data-aos="zoom-in-up">
                                    <div className={style.coverbg} ></div>
                                    <Lazyimg
                                        className="lazy"
                                        src={require("../img/la.png")}
                                    />
                                </div>
                                <div className={style.workerone} data-aos="zoom-in-up">
                                    <div className={style.coverbg} ></div>
                                    <Lazyimg
                                        className="lazy"
                                        src={require("../img/xing.png")}
                                    />
                                </div>
                                <div className={style.workerone} data-aos="zoom-in-up">
                                    <div className={style.coverbg} ></div>
                                    <Lazyimg
                                        className="lazy"
                                        src={require("../img/xiang.png")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Bottomnav />
                </div >
                );
            }
        }
        
export default Home;