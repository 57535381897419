import React, { Component } from 'react';
import TopnavM from "../../components/TopnavM/TopnavM.js";
import BottomnavM from "../../components/BottomnavM/BottomnavM";
import style from "./Aboutus.module.css";
import Swiper from 'swiper/dist/js/swiper.js';
import Lazyimg from 'react-lazyimg-component';
import Axios from '../../model/Axios';

class Aboutus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: {},
            company: []
        };
    }
    componentDidMount() {

        Axios.get("/home/about")
            .then((res) => {
                let data = res.data.res;
                this.setState({
                    config: data.config,
                    company: data.company
                })
                console.log(this.state.config);
                new Swiper('#story', {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true,  //循环
                    observer: true,
                    observeParents: true,
                    autoplay: {   //滑动后继续播放
                        disableOnInteraction: false,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }
    render() {
        return (
            <div className={style.about_us}>
                <TopnavM />
                <div className={style.top}>
                    <img src={require("../img/aboutus_bg.png")} alt="" />
                </div>
                <div className="center">
                    <div className={style.compony}>
                        <div className={style.left}>
                            <img src={require("../img/compony.png")} alt="" className={style.rightpic} />
                        </div>
                        <div className={style.right}>

                            {/* <h2>Company profile</h2> */}
                            <h2>公司简介</h2>
                            <span></span>
                            <p>
                                {this.state.config.describe}
                            </p>
                            <div className={style.plus}>+</div>
                        </div>
                    </div>
                    {/* <div className={style.componyprofile}>
                        Company profile
                    </div> */}
                    <div className={style.brandstory}>
                        <div className={style.title}>
                            {/* <h2>Brand story</h2> */}
                            <h2>品牌荣誉</h2>
                        </div>


                        <div className={["swiper-container", style.storyshow].join(" ")} id="story">
                            <div className="swiper-wrapper">

                                {
                                    this.state.company.map((item, ind) => {
                                        return <div className="swiper-slide" key={ind}>
                                            <div className={style.storyone}>
                                                <img src={`http://www.qpccx.com:8877${item.image}`} alt="" className={style.storyonepic} />
                                            </div>
                                            <p>
                                                {item.describe}
                                            </p>
                                        </div>
                                    })
                                }
                            </div>
                            <div className={["swiper-button-prev", style.swiperbnt].join(" ")}></div>
                            <div className={["swiper-button-next", style.swiperbntN].join(" ")}></div>
                        </div>
                        {/* <div className={style.componyprofile}>Brand story</div> */}
                    </div>
                    {/* <div className={style.brandhonor}>
                        <div className={style.title}>
                            <h2>Brand honor</h2>
                            <h2>品牌荣誉</h2>
                        </div>
                        <div className={style.honordetail}>
                            <div className={style.left}>
                                <Lazyimg
                                    className="lazy"
                                    src={require("../img/honor.png")}
                                />
                            </div>
                            <div className={style.right}>{this.state.config.content}</div>
                        </div>
                        <div className={style.componyprofile}>Brand honor</div>
                    </div> */}
                </div>
                <BottomnavM />
            </div>
        );
    }
}

export default Aboutus;