import React, { Component } from 'react';
import Topnav from '../../components/Topnav/Topnav';
import Bottomnav from '../../components/Bottomnav/Bottomnav';
import style from "./News.module.css";
import { Link } from 'react-router-dom';
import Axios from '../../model/Axios';
class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news_one: [],
            news_two: []
        }
    }
    componentDidMount() {
        Axios.get("/home/news")
            .then((res) => {
                let data = res.data.news;
                this.setState({
                    news_one: data.slice(0, 4),
                    news_two: data.slice(4)
                })
                console.log(this.state.news_one);
            })
            .catch((err) => {
                console.log(err)
            })
    }
    render() {
        return (
            <div>
                <Topnav />
                <div className={style.top}>
                </div>
                <div className="center">
                    <div className={style.newspart}>
                        {
                              

                            this.state.news_one.map((item, ind) => {
                                var data = {id:item.id};
                                var path = {pathname:'/Newsdetail.html',state:data,}
                                // console.log("id+"+item.id);
                                return <div className={style.newsone} key={ind} data-aos="zoom-in-up">


                                 
                                  
                                  
                                  
                                    <Link to={path}>

                                        <div className={style.newsimg}>
                                            <img src={`http://www.qpccx.com:8877${item.img}`} alt="" className={style.newsimgone} />
                                        </div>
                                        <h5>{item.title}</h5>
                                        <p>{item.remark}</p>
                                        <div className={style.more}>more</div>
                                        <div className={style.datee}>{item.update_time}</div>
                                        <div className={style.line}></div>
                                    </Link>

                                </div>
                            })
                        }

                        <ul className={style.newstwo}>
                            {
                                this.state.news_two.map((item, ind) => {  
                                    var data = {id:item.id};
                                    var path = {pathname:'/Newsdetail.html',state:data,}  
                                    return <li key={ind} data-aos="zoom-in-up"><Link to={path}>{item.title}</Link></li>
                                })
                            }
                        </ul>

                    </div>
                </div>
                <Bottomnav />
            </div>
        );
    }
}

export default News;