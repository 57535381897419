import React, { Component } from 'react';
import style from "./TopnavM.module.css";
import { Link } from 'react-router-dom';
class TopnavM extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: [
                ["首页", "/"],
                ["关于我们", "/aboutus.html"],
                ["菜品展示", "/menushow.html"],
                ["新闻快讯", "/news.html"],
                ["门店展示", "/shopshow.html"],
                ["合作加盟", "/cooperation.html"],
                ["联系我们", "/contactus.html"]
            ],
            hover: "none",
        }
    }
    handleClose = () => {
        this.refs.isshow_nav.style.display = "none";
    }
    handleShow = () => {
        this.refs.isshow_nav.style.display = "block";
    }
    render() {
        return (
            <div>
                <div className={style.topnav}>
                    <div className="center">
                        <img src={require("../imgM/navmenuM.png")} alt="" className={style.nav_menu} onClick={this.handleShow} />
                        <img src={require("../imgM/logo.png")} alt="" className={style.logo} />
                    </div>
                </div>
                <div className={style.space_topnav}></div>
                <div className={style.side_nav} ref="isshow_nav">
                    <ul>
                        {
                            this.state.lists.map((item) => {
                                return <li key={item}><Link href="" to={{ pathname: item[1] }}>{item[0]}</Link></li>
                            })
                        }

                    </ul>
                    <div className={style.close} onClick={this.handleClose} >
                        <img src={require("../imgM/close_big.png")} alt="" />
                    </div>
                </div>
            </div>

        );
    }
}

export default TopnavM;