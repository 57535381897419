

// PC端组件引入

import Home from './views/Home/Home';
import Aboutus from "./views/Aboutus/Aboutus";
import Contactus from "./views/Contactus/Contactus";
import Cooperation from "./views/Cooperation/Cooperation";
import Menushow from "./views/Menushow/Menushow";
import News from "./views/News/News";
import Newsdetail from "./views/Newsdetail/Newsdetail";
import Shopshow from "./views/Shopshow/Shopshow";


//移动端组件引入
import Homem from './viewsMobile/Home/Home';
import Aboutusm from "./viewsMobile/Aboutus/Aboutus";
import Contactusm from "./viewsMobile/Contactus/Contactus";
import Cooperationm from "./viewsMobile/Cooperation/Cooperation";
import Menushowm from "./viewsMobile/Menushow/Menushow";
import Newsm from "./viewsMobile/News/News";
import Newsdetailm from "./viewsMobile/Newsdetail/Newsdetail";
import Shopshowm from "./viewsMobile/Shopshow/Shopshow";



let _isPhone = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)



// if (_isPhone) {
//   console.log(456)
// } else {
//   console.log(123)
// }

const routers = [
  {
    path: "/",
    component: _isPhone ? Homem : Home,
    exact: true
  },
  {
    path: "/aboutus.html",
    component: _isPhone ? Aboutusm : Aboutus,
  },
  {
    path: "/contactus.html",
    component: _isPhone ? Contactusm : Contactus,
  },
  {
    path: "/cooperation.html",
    component: _isPhone ? Cooperationm : Cooperation,
  },
  {
    path: "/menushow.html",
    component: _isPhone ? Menushowm : Menushow,
  },
  {
    path: "/news.html",
    component: _isPhone ? Newsm : News,
  },
  {
    path: "/shopshow.html",
    component: _isPhone ? Shopshowm : Shopshow,
  },
  {
    path: "/Newsdetail.html",
    component: _isPhone ? Newsdetailm : Newsdetail,
  }
]

export default routers;