import React, { Component } from 'react';
import Topnav from '../../components/Topnav/Topnav';
import Bottomnav from '../../components/Bottomnav/Bottomnav';
import style from "./Contactus.module.css";

class Contactus extends Component {
    componentDidMount() {
        this.renderMap()
    }


    // 初始化地图的方法
    renderMap = () => {
        
        let map = new window.BMap.Map("orderDetailMap"); //初始化地图，这个id和下面的id相对应，之所以将初始化的地图放到this对象上，是方便其他方法调用map对象
        let point = new window.BMap.Point("103.832953","30.698204");//定义一个中心点坐标
        let marker = new window.BMap.Marker(point);//创建标注
        let infoWindow=new window.BMap.InfoWindow("<p></p><p><b>成都七婆餐饮有限公司</b></p><p>电话：4000-888-096</p><p>地址：成都市温江区杨柳西路北段45号</p><p></p>");
        map.addControl(new window.BMap.NavigationControl());
        map.centerAndZoom(point, 15);
        map.enableScrollWheelZoom(true);
        map.addOverlay(marker);//方法addOverlay() 向地图中添加覆盖物
        marker.openInfoWindow(infoWindow,point);
        marker.addEventListener("click",function(){this.openInfoWindow(infoWindow);});
    }
    render() {
        return (
            <div>
                <Topnav />
                <div className={style.mapp} id="orderDetailMap"></div>
                    <div className="center">
                        <div className={style.content} data-aos="zoom-in-up">
                            {/* <div className={style.contentone}>公司名称</div> */}
                            <div className={style.contenttwo}>成都七婆餐饮有限公司</div>
                            <div className={style.contenttwo}>七婆餐饮24小时为您服务</div>
                        </div>
                        <div className={style.content} data-aos="zoom-in-up">
                            <div className={style.contentone}>总公司地址</div>
                            <div className={style.contenttwo}>成都市温江区杨柳西路北段45号</div>
                        </div>
                        <div className={style.content} data-aos="zoom-in-up">
                            <div className={style.contentone}>加盟热线</div>
                            <div className={style.contenttwo}>15520831111</div>
                            <div className={style.contenttwo}>17358974307</div>
                            <div className={style.contenttwo}>400-0888-096</div>
                        </div>
                        <div data-aos="zoom-in-up">
                            <img src={require("../img/pic_usus.png")} alt="" className={style.imgbottom} />
                        </div>
                    </div>
                    <Bottomnav />
                </div>
                );
            }
        }
        
export default Contactus;