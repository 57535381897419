
import React, { Component } from 'react';
import { HashRouter as Router } from 'react-router-dom';
// import { BrowserRouter as Router} from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import routers from "./routers";
import AOS from 'aos';
import 'aos/dist/aos.css';
class App extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    AOS.init({
      duration: 700,
      easing: 'ease-out-back',
      delay: 200
    })
  }

  render() {
    return (
      <div>
        <Router>
          {renderRoutes(routers)}
        </Router>
      </div>
    );
  }
}

export default App;

