import React, { Component } from 'react';
import Topnav from '../../components/Topnav/Topnav';
import Bottomnav from '../../components/Bottomnav/Bottomnav';
import style from "./Menushow.module.css";
import Lazyimg from 'react-lazyimg-component';
import 'velocity-animate';
import 'velocity-animate/velocity.ui';
import Axios from '../../model/Axios';
import Swiper from 'swiper/dist/js/swiper.js';
// import { relative } from 'path';
class Menushow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgc: "",
            pdcate: [],
            product: [],
            isFullScreen: false,
        };
    }

    componentDidMount() {

        Axios.get("/home/product")
            .then((res) => {
                let data = res.data.res;
                this.setState({
                    pdcate: data.pdcate,
                    product: data.product
                })
                // let lunboImg = document.getElementsByClassName("lunboImg");
                // console.log(lunboImg);
            })
            .catch((err) => {
                console.log(err)
            })
        this.watchFullScreen()
    }

    clickMenu = (ev) => {
        let Tag_name = ev.target.parentElement.parentElement.tagName;
        this.changeColor();
        if (Tag_name === "DIV") {
            ev.target.style.backgroundColor = "#CC0E17";
            ev.target.style.color = "white";
            Axios.post("/home/product", { id: ev.currentTarget.id })
                .then((res) => {
                    let data = res.data.res;
                    this.setState({
                        product: data.product
                    });
                })
                .catch(err => {
                    console.log(err)
                }
                )
        } else if (Tag_name === "LI") {
            ev.target.parentElement.parentElement.style.color = "white";
            ev.target.parentElement.parentElement.style.backgroundColor = "#CC0E17";
            Axios.post("/home/product", { id:ev.currentTarget.id})
                .then((res) => {
                    let data = res.data.res;
                    this.setState({
                        product: data.product
                    });
                })
                .catch(err => {
                    console.log(err)
                }
                )
        }

    }
    changeColor = () => {
        for (let i = 0; i < this.refs.menuNav.children.length; i++) {
            this.refs.menuNav.children[i].style.backgroundColor = "";
            this.refs.menuNav.children[i].style.color = "#797979";

        }
    }
    closeShow = () => {
        this.refs.isShowPro.style.display = "none";
    }
    showPro = (ev) => {
        this.refs.isShowPro.style.display = "block";
        let showID = ev.currentTarget.id;
        console.log("showID"+showID);
        // let myid = ev.currentTarget.getAttribute("data-index");
        // console.log("myid"+myid);
        new Swiper('#ProduShows', {
            spaceBetween: 0,
            initialSlide: showID,
            // observer: true,
            observeParents: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        })
    }


    fullScreen = () => {
        // console.log('fullscreen:', this.state.isFullScreen);
        if (!this.state.isFullScreen) {
            this.requestFullScreen();
            this.setState({
                isFullScreen: true
            })
        } else {
            this.exitFullscreen();
            this.setState({
                isFullScreen: false
            })
        }
    };

    //进入全屏
    requestFullScreen = () => {
        // console.log('requestFullScreen')
        var de = document.documentElement;
        if (de.requestFullscreen) {
            de.requestFullscreen();
        } else if (de.mozRequestFullScreen) {
            de.mozRequestFullScreen();
        } else if (de.webkitRequestFullScreen) {
            de.webkitRequestFullScreen();
        }
    };

    //退出全屏
    exitFullscreen = () => {
        // console.log('exitFullscreen')
        var de = document;
        if (de.exitFullscreen) {
            de.exitFullscreen();
        } else if (de.mozCancelFullScreen) {
            de.mozCancelFullScreen();
        } else if (de.webkitCancelFullScreen) {
            de.webkitCancelFullScreen();
        }
    };

    //监听fullscreenchange事件
    watchFullScreen = () => {
        const _self = this;
        document.addEventListener(
            "webkitfullscreenchange",
            function () {
                _self.setState({
                    isFullScreen: document.webkitIsFullScreen
                });
            },
            false
        );
    };

    render() {
        return (
            <div>
                <Topnav />
                <div className={style.menuTop}>
                </div>
                <div className="center">
                    <div className={style.mainmenu}>
                        <ul className={style.menuNav} ref="menuNav"  data-aos="zoom-in-up">
                            {
                                this.state.pdcate.map((item, ind) => {
                                return <li key={ind} id={item.id} onClick={this.clickMenu} style={{ backgroundColor: this.state.bgc }} className={style.menuNavItem}>{item.name}</li>
                                })
                            }
                        </ul>
                        <div className={style.menuInfo} ref="menuInfo">

                            {
                                this.state.product.map((item, ind) => {
                                    return <div className={style.menuOne} key={ind} onClick={this.showPro} id={ind} data-index ={item.id} >
                                        <div className={style.menuinfoss} id={ind}>
                                            <div className={style.menuImg} id={ind}>
                                                <Lazyimg
                                                    className="lazy"
                                                    src={`http://www.qpccx.com:8877${item.img}`}
                                                    js_effect="transition.shrinkIn"
                                                    id={ind} />
                                            </div>
                                            <h5 className={style.tileName} id={ind}>{item.name}</h5>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                </div>

                <Bottomnav />
                <div className={style.showPro} ref="isShowPro">
                    <div className={style.showBtn}>
                        <div className={style.showOne} onClick={this.closeShow} >
                        </div>
                        <div className={style.showTwo} onClick={this.fullScreen}>
                        </div>
                    </div>
                    {/* 弹出层轮播 */}
                    <div className={["swiper-container", style.ProduShow].join(" ")} id="ProduShows" >
                        <div className="swiper-wrapper" >
                            {
                                this.state.product.map((item, ind) => {
                                    // console.log(item.img)
                                    return <div className="swiper-slide" key={ind} style={{ width: "1200px" }} >
                                        <div className={style.menuOneT} key={ind}>
                                            <div className={style.menuinfossT}>
                                                <div className={style.menuImgT} >
                                                    <img src={`http://www.qpccx.com:8877${item.img}`} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="swiper-button-prev swiper-button-white" style={{ width: "60px", height: "60px", backgroundColor: "#CC0E17", backgroundSize: "26px 26px" }}></div>
                        <div className="swiper-button-next swiper-button-white" style={{ width: "60px", height: "60px", backgroundColor: "#CC0E17", backgroundSize: "26px 26px" }}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Menushow;