import React, { Component } from 'react';
import TopnavM from "../../components/TopnavM/TopnavM.js";
import BottomnavM from "../../components/BottomnavM/BottomnavM";
import style from "./News.module.css";
import { Link } from 'react-router-dom';
import Axios from '../../model/Axios';

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news_one: [],
            news_two: []
        }
    }
    componentDidMount() {
        Axios.get("/home/news")
            .then((res) => {
                let data = res.data.news;
                // console.log(data.slice(0,4));
                // console.log(data.slice(4));
                this.setState({
                    news_one: data.slice(0, 4),
                    news_two: data.slice(4)
                })
                // console.log(this.state.news_one)

            })
            .catch((err) => {
                console.log(err)
            })
    }
    render() {
        return (
            <div>
                <TopnavM />
                <div className={style.top}>
                    <img src={require("../img/newsbgtop.png")} alt="" />
                </div>
                <div className="center">
                    <div className={style.newspart}>
                        {
                            this.state.news_one.map((item, ind) => {
                                var data = {id:item.id};
                                var path = {pathname:'/Newsdetail.html',state:data}
                                return <div className={style.newsone} key={ind}>
                                    <Link to={ path }>

                                        <div className={style.newsimg}>
                                            <img src={`http://www.qpccx.com:8877${item.image}`} alt="" className={style.newsimgone} />
                                        </div>
                                        <h5>{item.title}</h5>
                                        <p>{item.remark}</p>
                                        <div className={style.more}>more</div>
                                        <div className={style.datee}>{item.update_time}</div>
                                        <div className={style.line}></div>
                                    </Link>

                                </div>
                            })
                        }
                        <ul className={style.newstwo}>
                            {
                                this.state.news_two.map((item, ind) => {
                                    var data = {id:item.id};
                                    var path = {pathname:'/Newsdetail.html',state:data}
                                    return <li key={ind}><Link to={ path}>{item.title}</Link></li>
                                })
                            }
                        </ul>

                    </div>
                </div>
                <BottomnavM />
            </div>
        );
    }
}

export default News;