import React, { Component } from 'react';
import Topnav from '../../components/Topnav/Topnav';
import Bottomnav from '../../components/Bottomnav/Bottomnav';
import style from "./Cooperation.module.css";
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'
class Cooperation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logpicOne: require("../img/lgcoo.png"),
            newAray: [{ title: "合作咨询", des: "对本公司和此项目优势详细了解后，可以通过电话、网上或发传真的方式咨询" }, { title: "考察评估", des: "派人前来实地考察，品味，参观" }, { title: "合同签订", des: "签订合同，并确立开店时间" }, { title: "加盟培训", des: "公司为加盟商培训相关人员，技术指导，全线展示操作流程" }, { title: "购置材料", des: "购置设备和物资" }, { title: "开业筹备", des: "办理营业手续，总部管理技术督导人员正式进场" }],
            // bgcPic: [url("../img/joinpiconebg.png")],
            lgpic:[require("../img/joinpicone.png"),require("../img/joinpictwo.png"),require("../img/joinpicthree.png"),require("../img/joinpicfour.png"),require("../img/joinpicfive.png"),require("../img/joinpicfive.png")]
        }
    }
    componentDidMount() {
        new Swiper('.swiper-container', {
            loop: true,  //循环
            autoplay: {   //滑动后继续播放（不写官方默认暂停）
                disableOnInteraction: false,
            },
            // pagination: {  //分页器
            //     el: '.swiper-pagination',
            //     clickable: true,
            // },
            slidesPerView: 3,
            spaceBetween: 10,
            // effect: 'coverflow',
            parallax: true,
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },

        })
    }
    handleMouseEnter = () => {
        this.setState({
            logpicOne: require("../img/joinpicone.png")
        })
    }
    handleMouseLeave = () => {
        this.setState({
            logpicOne: require("../img/lgcoo.png")
        })
    }
    render() {
        return (
            <div>
                <Topnav />
                <div className={style.top}>
                </div>
                <div className="center">
                    {/* 品牌优势 */}
                    <div className={style.brandadv}>
                        <div className={style.titletop} data-aos="zoom-in-up"> <img src={require("../img/adv.png")} alt="" /></div>
                        <div className={style.brandadvbot} data-aos="zoom-in-up">
                            <div className={style.brandadvleft}>
                                <img src={require("../img/brandadvleft.png")} alt="" />
                            </div>
                            <div className={style.brandadvright}>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advone.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>选址支持</div>
                                        <div className={style.advbot}>协助选址，量身定制装修规则，把好开店第一关</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advtwo.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>实操培训</div>
                                        <div className={style.advbot}>开业前技术培训，开业后产品经理驻店20天手把手传授，持续技术指导</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advthree.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>店面装修</div>
                                        <div className={style.advbot}>根据实际店面情况，提供装修建议方案，提供施工跟进服务</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advfour.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>免费培训</div>
                                        <div className={style.advbot}>专业运营导师讲解开业前的准备事项，不走弯路，早开业早经营</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advfive.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>广告支持</div>
                                        <div className={style.advbot}>常年投放电视广告、网络广告、杂志媒体广告</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advsix.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>营销支持</div>
                                        <div className={style.advbot}>优惠不断，常年活动不断，针对四季节令进行不同力度的促销活动</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advsix.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>售后支持</div>
                                        <div className={style.advbot}>总部、华南两大服务运营中心，服务经理跟踪支援服务</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advsix.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>技术支持</div>
                                        <div className={style.advbot}>总部针对不同区域，持续不断的进行新产品开发，并传授新品技术</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* 加盟流程 */}
                    <div className={style.joinprocess}>
                        <div className={style.titletopT}> <img src={require("../img/joinnn.png")} alt="" /></div>
                        <div className={style.joinshow}>
                            <div className={[style.joinshowone, style.joinone].join(" ")} >
                                <div className={style.joinshowpic}><img src={require("../img/lgcoo.png")} alt="" /></div>
                                <div className={[style.joinshowtile, style.jointwoo].join(" ")}>合作咨询</div>
                                <div className={[style.line, style.lineoo].join(" ")}></div>
                                <p className={style.content}>对本公司和此项目优势详细了解后，可以通过电话、网上或发传真的方式咨询
                                </p>
                            </div>
                            <div className={[style.joinshowone, style.jointwo].join(" ")} >
                                <div className={style.joinshowpic}><img src={require("../img/joinpictwo.png")} alt="" /></div>
                                <div className={[style.joinshowtile, style.jointwoo].join(" ")}>考察评估</div>
                                <div className={[style.line, style.lineoo].join(" ")}></div>
                                <p className={style.content}>派人前来实地考察，品味，参观
                                </p>
                            </div>
                            <div className={[style.joinshowone, style.jointhree].join(" ")}>
                                <div className={style.joinshowpic}><img src={require("../img/joinpicthree.png")} alt="" /></div>
                                <div className={[style.joinshowtile, style.jointwoo].join(" ")}>合同签订</div>
                                <div className={[style.line, style.lineoo].join(" ")}></div>
                                <p className={style.content}>签订合同，并确立开店时间
                                </p>
                            </div>
                            <div className={[style.joinshowone, style.joinfour].join(" ")}>
                                <div className={style.joinshowpic}><img src={require("../img/joinpicfour.png")} alt="" /></div>
                                <div className={[style.joinshowtile, style.jointwoo].join(" ")}>加盟培训</div>
                                <div className={[style.line, style.lineoo].join(" ")}></div>
                                <p className={style.content}>公司为加盟商培训相关人员，技术指导，全线展示操作流程
                                </p>
                            </div>
                            <div className={[style.joinshowone, style.joinfive].join(" ")}>
                                <div className={style.joinshowpic}><img src={require("../img/joinpicfive.png")} alt="" /></div>
                                <div className={[style.joinshowtile, style.jointwoo].join(" ")}>购置材料</div>
                                <div className={[style.line, style.lineoo].join(" ")}></div>
                                <p className={style.content}>购置设备和物资
                                </p>
                            </div>
                            <div className={[style.joinshowone, style.joinsix].join(" ")}>
                                <div className={style.joinshowpic}><img src={require("../img/joinpicfive.png")} alt="" /></div>
                                <div className={[style.joinshowtile, style.jointwoo].join(" ")}>开业筹备</div>
                                <div className={[style.line, style.lineoo].join(" ")}></div>
                                <p className={style.content}>办理营业手续，总部管理技术督导人员正式进场
                                </p>
                            </div>

                        </div>
                    </div>


                    {/* 加盟支持 */}
                    <div className={style.support} data-aos="zoom-in-up">
                        <div className={style.titletopT}> <img src={require("../img/support.png")} alt="" /></div>
                        <div className={style.supportlunbo}>
                            <div className="swiper-container" style={{ width: "1200px", height: "400px" }}>
                                <div className="swiper-wrapper">
                                    <div className={["swiper-slide", style.perbox].join(" ")}>
                                        <img src={require("../img/cuipi.jpg")} alt="" className={style.newsimgone} />
                                    </div>
                                    <div className={["swiper-slide", style.perbox].join(" ")}>
                                        <img src={require("../img/cuipiT.jpg")} alt="" className={style.newsimgone} />
                                    </div>
                                    <div className={["swiper-slide", style.perbox].join(" ")} >
                                        <img src={require("../img/cuipiTh.jpg")} alt="" className={style.newsimgone} />
                                    </div>
                                </div>
                                <div className="swiper-pagination"></div>
                                <div className="swiper-scrollbar"></div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* 底部图 */}
                <div className={style.coobot}>
                </div>
                <Bottomnav />
            </div>
        );
    }
}

export default Cooperation;