import React, { Component } from 'react';
import Topnav from '../../components/Topnav/Topnav';
import Bottomnav from '../../components/Bottomnav/Bottomnav';
import style from "./shopshow.module.css";
import Axios from '../../model/Axios';
// import { Link } from 'react-router-dom';

class Shopshow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopshow: []
        }
    }
    componentDidMount() {
        Axios.get("home/room/index")
            .then((res) => {
                let data = res.data.res.product;
                this.setState({
                    shopshow: data
                });
                console.log(this.state.shopshow)
            })
            .catch(err => {
                console.log(err);
            })
    }
    render() {
        return (
            <div>
                <Topnav />
                <div className={style.top}>
                </div>
                <div className={style.center}>
                    {
                        this.state.shopshow.map((item, ind) => {
                            return <div className={style.shopone} key={ind} data-aos="zoom-in-up">
                                <div className={style.left}>
                                    <img src={`http://www.qpccx.com:8877${item.img}`} alt="" className={style.imgbig} />>
                            </div>
                                <div className={style.right}>
                                    <h3>{item.title}</h3>
                                    <div className={style.line} style={{marginBottom:"6rem"}}></div>
                                    {/* <div className={style.tel}>联系热线：{item.tel}</div>
                                    <div className={style.address}>地址：{item.address}</div> */}
                                </div>
                            </div>
                        })
                    }

                    {/* <div className={style.shopone}>
                        <div className={style.left}>
                            <img src={require("../img/shopdetailpic.png")} alt="" className={style.imgbig}/>
                        </div>
                        <div className={style.right}>
                            <h3>七婆串串香温江店</h3>
                            <div className={style.line}></div>
                            <div className={style.tel}>联系热线：028-88880000</div>
                            <div className={style.address}>地址：四川省成都市温江区杨柳河具体地址</div>
                        </div>
                    </div>

                    
                    <div className={style.shopone}>
                        <div className={style.left}>
                            <img src={require("../img/shopdetailpic.png")} alt="" className={style.imgbig}/>>
                        </div>
                        <div className={style.right}>
                            <h3>七婆串串香温江店</h3>
                            <div className={style.line}></div>
                            <div className={style.tel}>联系热线：028-88880000</div>
                            <div className={style.address}>地址：四川省成都市温江区杨柳河具体地址</div>
                        </div>
                    </div>
                    <div className={style.shopone}>
                        <div className={style.left}>
                            <img src={require("../img/shopdetailpic.png")} alt="" className={style.imgbig}/>>
                        </div>
                        <div className={style.right}>
                            <h3>七婆串串香温江店</h3>
                            <div className={style.line}></div>
                            <div className={style.tel}>联系热线：028-88880000</div>
                            <div className={style.address}>地址：四川省成都市温江区杨柳河具体地址</div>
                        </div>
                    </div> */}
                </div>
                <Bottomnav />
            </div>
        );
    }
}

export default Shopshow;