import React, { Component } from 'react';
import style from './Topnav.module.css';
import { Link } from 'react-router-dom';
class Topnav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: [
                ["首页", "/"],
                ["关于我们", "/aboutus.html"],
                ["菜品展示", "/menushow.html"],
                ["新闻快讯", "/news.html"],
                ["门店展示", "/shopshow.html"],
                ["合作加盟", "/cooperation.html"],
                ["联系我们", "/contactus.html"]
            ],
            hover: "none",
        }
    }
    handleClick = (ev) => {
        // console.log(999);
        ev.target.style.backgroundColor = "#A30008";
        // console.log(ev.target.style.backgroundColor)

    }
    handlemouseEnter = () => {
        // this.setState({ hover: "flex" })
    }
    handlemouseLeave = () => {
        // this.setState({ hover: "none" })
    }

    // 导航栏搜索
    wantSearch = () => {
        this.refs.search_page.style.display = "block";
    }
    closeSearch = () => {
        this.refs.search_page.style.display = "none";
    }

    render() {
        return (
            <div>
                <div onMouseLeave={this.handlemouseLeave} className={style.center} >
                    <div className={style.topnav} >
                        <img src={require("./img/LOGO.png")} className={style.left} alt="" data-aos="zoom-out-down" />
                        <ul className={style.right} data-aos="zoom-out-down">
                            {
                                this.state.lists.map(item => {
                                    return <Link key={item} to={{ pathname: item[1] }} ><li onClick={this.handleClick} key={item} onMouseEnter={this.handlemouseEnter}>  {item[0]} </li></Link>;
                                })
                            }
                            {/* <li className={style.search} onClick={this.wantSearch}>
                                    <img src={require("./img/search.png")} alt=""  />
                            </li> */}
                        </ul>
                    </div>
                    <div className={style.clear}></div>
                    {/* 是否需要二级菜单 */}
                    <div className={style.space}></div>
                    <div className={style.navinfo} style={{ display: this.state.hover }}>
                        <div className={style.left}>
                            <img src={require("./img/tel.png")} alt="" />
                            <ul>
                                <li>全国加盟服务热线</li>
                                <li>4000-888-096</li>
                            </ul>
                        </div>
                        <ul className={style.right}>
                            <li>
                                <ul>
                                    <li><Link to={{ pathname: "##" }}></Link></li>
                                    <li><Link to={{ pathname: "##" }}></Link></li>
                                    <li><Link to={{ pathname: "##" }}></Link></li>
                                    <li><Link to={{ pathname: "##" }}></Link></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><Link to={{ pathname: "##" }}>公司简介</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                </ul>
                            </li>
                            <li>
                                <ul>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                    <li><Link to={{ pathname: "##" }}>1</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={style.search_page} ref = "search_page">
                    <div className={style.search_center}>
                        <img src={require("./img/search_close.png")} alt="" ref = "close_search" onClick = {this.closeSearch}/>
                        <input type="text"  ref = "search_input" placeholder = "请输入搜索内容"/>
                        <img src={require("./img/search_s.png")} alt="" ref = "do_search"/>
                    </div>

                </div>
            </div>
        );
    }
}

export default Topnav;