import React, { Component } from 'react';
import Topnav from '../../components/Topnav/Topnav';
import Bottomnav from '../../components/Bottomnav/Bottomnav';
import style from "./Aboutus.module.css";
import Lazyimg from 'react-lazyimg-component'
import Axios from '../../model/Axios';
class Aboutus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: {},
            companyOne: {},
            companyTwo: {},
            companyThr: {},
        };
    }
    componentDidMount() {
        Axios.get("/home/about")
            .then((res) => {
                let data = res.data.res;
                this.setState({
                    config: data.config,
                    companyOne: data.company[0],
                    companyTwo: data.company[1],
                    companyThr: data.company[2],
                })
                console.log(this.state.config);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    render() {
        return (
            <div>
                <Topnav />
                <div className={style.top}>
                </div>
                <div className="center">
                    <div className={style.compony}>
                        <div className={style.left} >
                            <img src={require("../img/compony.png")} alt="" className={style.rightpic} data-aos="zoom-in-up" />
                        </div>
                        <div className={style.right} data-aos="zoom-in-up">

                            {/* <h2>Company profile</h2> */}
                            <h2>公司简介</h2>
                            <span></span>
                            <p data-aos="zoom-in-up">
                                {this.state.config.describe}
                            </p>
                            <div className={style.plus} data-aos="zoom-in-up">+</div>
                        </div>
                    </div>
                    {/* <div className={style.componyprofile}  data-aos="zoom-in-up">
                        Company profile
                    </div> */}
                    <div className={style.brandstory}>
                        <div className={style.title} data-aos="zoom-in-up">
                            {/* <h2 >Brand story</h2> */}
                            <h2 data-aos="zoom-in-up">品牌荣誉</h2>
                        </div>
                        <div className={style.storyshow}>
                            <div className={style.storyone} data-aos="zoom-in-up">
                                <img src={`http://www.qpccx.com:8877${this.state.companyOne.img}`} alt="" className={style.storyonepic} />
                            </div>
                            <div data-aos="zoom-in-up" className={style.storyone}>
                                <img src={require("../img/honerT.jpg")} alt="" className={style.storyonepic} />
                                {/* <p>
                                    {this.state.companyOne.describe}
                                </p> */}
                            </div>
                            <div className={style.storyone} data-aos="zoom-in-up">
                                <img src={`http://www.qpccx.com:8877${this.state.companyTwo.img}`} alt="" className={style.storyonepic} />
                            </div>
                            <div className={style.storyone} data-aos="zoom-in-up">
                                <img src={`http://www.qpccx.com:8877${this.state.companyThr.img}`} alt="" className={style.storyonepic} />
                            </div>
                            <div data-aos="zoom-in-up" className={style.storyone}>
                                <img src={require("../img/honerTh.jpg")} alt="" className={style.storyonepic} />
                                {/* <p>
                                    {this.state.companyTwo.describe}
                                </p> */}
                            </div>

                            <div data-aos="zoom-in-up" className={style.storyone}>
                                <img src={require("../img/honerFo.jpg")} alt="" className={style.storyonepic} />
                                {/* <p>
                                    {this.state.companyThr.describe}
                                </p> */}
                            </div>
                        </div>
                        {/* <div className={style.componyprofile} data-aos="zoom-in-up">Brand story</div> */}
                    </div>
                    {/* <div className={style.brandhonor}>
                        <div className={style.title} data-aos="zoom-in-up">
                            <h2>Brand honor</h2>
                            <h2>品牌荣誉</h2>
                        </div>
                        <div className={style.honordetail} data-aos="zoom-in-up">
                            <div className={style.left}>
                                <Lazyimg
                                    className="lazy"
                                    src={require("../img/honor.png")}
                                />
                            </div>
                            <div className={style.right}>{this.state.config.content}</div>
                        </div>
                        <div className={style.componyprofile} data-aos="zoom-in-up">Brand honor</div>
                    </div> */}

                </div>

                <Bottomnav />

            </div>
        );
    }
}

export default Aboutus;