import React, { Component } from 'react';
import TopnavM from "../../components/TopnavM/TopnavM.js";
import BottomnavM from "../../components/BottomnavM/BottomnavM";
import style from "./Cooperation.module.css";
import Swiper from 'swiper/dist/js/swiper.js'
import 'swiper/dist/css/swiper.min.css'

class Cooperation extends Component {
  
    componentDidMount() {
        new Swiper('.swiper-container', {
            // pagination: {
            //     el: '.swiper-pagination',
            //     clickable :true,
            //     bulletActiveClass: 'my-bullet-active',

            //   },
            slidesPerView: 2,
            spaceBetween: 10,
            loop: true,  //循环
            observer: true,
            observeParents: true,
            autoplay: {   //滑动后继续播放
                disableOnInteraction: false,
            },
        })
    }

    render() {
        return (
            <div>
                <TopnavM />
                <div className={style.top}>
                    <img src={require("../img/coobgtop.png")} alt="" />
                </div>
                <div className="center">
                    {/* 品牌优势 */}
                    <div className={style.brandadv}>
                    {/* className={["swiper-slide", style.menu_item].join(" ")} */}
                        <div className={[style.titletop,style.titletopOne].join(" ")}> <img src={require("../img/adv.png")} alt="" /></div>
                        <div className={style.brandadvbot}>
                            <div className={style.brandadvleft}>
                                <img src={require("../img/brandadvleft.png")} alt="" />
                            </div>
                            <div className={style.brandadvright}>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advone.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>选址支持</div>
                                        <div className={style.advbot}>协助 选址，量身定制装修规则，把好开店第一关</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advtwo.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>实操培训</div>
                                        <div className={style.advbot}>开业前技术培训，开业后产品经理驻店20天手把手传授，持续技术指导</div>
                                    </div>
                                </div>
                     
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advfour.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>店面装修</div>
                                        <div className={style.advbot}>根据实际店面情况，提供装修建议方案，提供施工跟进服务</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advfive.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>免费培训</div>
                                        <div className={style.advbot}>专业运营导师讲解开业前的准备事项，不走弯路，早开业早经营</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advsix.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>广告支持</div>
                                        <div className={style.advbot}>常年投放电视广告、网络广告、杂志媒体广告</div>
                                    </div>
                                </div>

                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advsix.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>营销支持</div>
                                        <div className={style.advbot}>优惠不断，常年活动不断，针对四季节令进行不同力度的促销活动</div>
                                    </div>
                                </div>
                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advsix.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>售后支持</div>
                                        <div className={style.advbot}>总部、华南两大服务运营中心，服务经理跟踪支援服务</div>
                                    </div>
                                </div>

                                <div className={style.brandadvrightdetail}>
                                    {/* <div className={style.advleft}>
                                        <img src={require("../img/advsix.png")} alt="" />
                                    </div> */}
                                    <div className={style.advright}>
                                        <div className={style.advtop}>技术支持</div>
                                        <div className={style.advbot}>总部针对不同区域，持续不断的进行新产品开发，并传授新品技术</div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                    {/* 加盟流程 */}
                    <div className={style.joinprocess}>
                        <div className={style.titletop}> <img src={require("../img/joinnn.png")} alt="" /></div>
                        <div className={style.joinshow}>
                            <div className={[style.joinshowone,style.joinone].join(" ")} >
                                <div className={style.joinshowpic}><img src={require("../img/lgcoo.png")} alt="" /></div>
                                <div className={[style.joinshowtile,style.jointwoo].join(" ")}>合作咨询</div>
                                <div className={[style.line,style.lineoo].join(" ")}></div>
                                <p className = {style.content}>对本公司和此项目优势详细了解后，可以通过电话、网上或发传真的方式咨询
                                </p>
                            </div>
                            <div className={[style.joinshowone,style.jointwo].join(" ")}>
                                <div className={style.joinshowpic}><img src={require("../img/joinpictwo.png")} alt="" /></div>
                                <div className={[style.joinshowtile,style.jointwoo].join(" ")}>考察评估</div>
                                <div className={[style.line,style.lineoo].join(" ")}></div>
                                <p className = {style.content}>派人前来实地考察，品味，参观
                                </p>
                            </div>
                            <div className={[style.joinshowone,style.jointhree].join(" ")}>
                                <div className={style.joinshowpic}><img src={require("../img/joinpicthree.png")} alt="" /></div>
                                <div className={[style.joinshowtile,style.jointwoo].join(" ")}>合同签订</div>
                                <div className={[style.line,style.lineoo].join(" ")}></div>
                                <p className = {style.content}>签订合同，并确立开店时间
                                </p>
                            </div>
                            <div className={[style.joinshowone,style.joinfour].join(" ")}>
                                <div className={style.joinshowpic}><img src={require("../img/joinpicfour.png")} alt="" /></div>
                                <div className={[style.joinshowtile,style.jointwoo].join(" ")}>加盟培训</div>
                                <div className={[style.line,style.lineoo].join(" ")}></div>
                                <p className = {style.content}>公司为加盟商培训相关人员，技术指导，全线展示操作流程
                                </p>
                            </div>
                            <div className={[style.joinshowone,style.joinfive].join(" ")}>
                                <div className={style.joinshowpic}><img src={require("../img/joinpicfive.png")} alt="" /></div>
                                <div className={[style.joinshowtile,style.jointwoo].join(" ")}>购置材料</div>
                                <div className={[style.line,style.lineoo].join(" ")}></div>
                                <p className = {style.content}>购置设备和物资
                                </p>
                            </div>
                            <div className={[style.joinshowone,style.joinsix].join(" ")}>
                                <div className={style.joinshowpic}><img src={require("../img/joinpicsix.png")} alt="" /></div>
                                <div className={[style.joinshowtile,style.jointwoo].join(" ")}>开业筹备</div>
                                <div className={[style.line,style.lineoo].join(" ")}></div>
                                <p className = {style.content}>办理营业手续，总部管理技术督导人员正式进场
                                </p>
                            </div>

                        </div>
                    </div>


                    {/* 加盟支持 */}
                    <div className={style.support}>
                        <div className={style.titletop}> <img src={require("../img/support.png")} alt="" /></div>
                        <div className={style.supportlunbo}>
                            <div className="swiper-container">
                                <div className="swiper-wrapper">
                                    <div className={["swiper-slide", style.perbox].join(" ")}>
                                        <img src={require("../img/cuipi.jpg")} alt="" className={style.newsimgone} />
                                    </div>
                                    <div className={["swiper-slide", style.perbox].join(" ")}>
                                        <img src={require("../img/cuipiT.jpg")} alt="" className={style.newsimgone} />
                                    </div>
                                    <div className={["swiper-slide", style.perbox].join(" ")} >
                                        <img src={require("../img/cuipiTh.jpg")} alt="" className={style.newsimgone} />
                                    </div>
                                </div>
                                <div className="swiper-pagination"></div>
                                <div className="swiper-scrollbar"></div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* 底部图 */}
                <div className={style.coobot}>
                    <img src={require("../img/coobot.png")} alt="" />
                </div>
                <BottomnavM />
            </div>
        );
    }
}

export default Cooperation;