import React, { Component } from 'react';
import TopnavM from "../../components/TopnavM/TopnavM.js";
import BottomnavM from "../../components/BottomnavM/BottomnavM";
import style from "./Shopshow.module.css";
import Axios from '../../model/Axios';

class Shopshow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shopshow: []
        }
    }
    componentDidMount() {
        Axios.get("home/room/index")
            .then((res) => {
                let data = res.data.res.product;
                this.setState({
                    shopshow: data
                });
                console.log(this.state.shopshow)
            })
            .catch(err => {
                console.log(err);
            })
    }
    render() {
        return (
            <div>
                <TopnavM />
                <div className={style.top}>
                    <img src={require("../img/shopshowbg.png")} alt="" />
                </div>
                <div className="center">
                    {
                        this.state.shopshow.map((item, ind) => {
                            return <div className={style.shopone} key={ind}>
                                <div className={style.left}>
                                    <img src={`http://www.qpccx.com:8877${item.image}`} alt="" className={style.imgbig} />
                                </div>
                                <div className={style.right}>
                                    <h3>{item.title}</h3>
                                    <div className={style.line}></div>
                                    {/* <div className={style.tel}>联系热线：{item.tel}</div>
                                    <div className={style.address}>地址：{item.address}</div> */}
                                </div>
                            </div>
                        })
                    }
                </div>
                <BottomnavM />
            </div>
        );
    }
}

export default Shopshow;