import React, { Component } from 'react';
import TopnavM from "../../components/TopnavM/TopnavM.js";
import BottomnavM from "../../components/BottomnavM/BottomnavM";
import style from "./Home.module.css";
import Swiper from 'swiper/dist/js/swiper.js';
import Lazyimg from 'react-lazyimg-component';
import { Link } from 'react-router-dom';
import axios from "../../model/Axios";
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgs: [],
            product: [],
            news: []
        };
    }
    componentDidMount() {

        new Swiper('#five_hot', {
            slidesPerView: 3,
            loop: true,  //循环
            observer: true,
            observeParents: true,
            autoplay: {   //滑动后继续播放
                disableOnInteraction: false,
            },
        })
        axios.get('/home/index')
            .then((res) => {

                let data = res.data.res;
                this.setState({
                    imgs: data.images,
                    product: data.product,
                    news: data.news[0]
                })
                console.log(this.state.news);
                new Swiper('#home_top', {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true,  //循环
                    observer: true,
                    observeParents: true,
                    autoplay: {   //滑动后继续播放
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    }
                })
                new Swiper('#menu_show', {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true,  //循环
                    observer: true,
                    observeParents: true,
                    autoplay: {   //滑动后继续播放
                        disableOnInteraction: false,
                    },
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }
    render() {
        return (
            <div>
                <TopnavM />
                {/* 第一个轮播 */}
                <div className={style.lunbo_top}>
                    <div className={[style.img_round, "swiper-container"].join(" ")} id="home_top">
                        <ul className="swiper-wrapper">
                            {
                                this.state.imgs.map((item, ind) => {
                                    console.log(item);
                                    return <li className="swiper-slide" key={ind}>
                                        <img src={`http://www.qpccx.com:8877${item.image}`} alt="" />
                                    </li>
                                })
                            }
                        </ul>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
                {/* 关于我们 */}
                <div className={style.aboutus}>
                    <div className={["center", style.mycenter].join(" ")}>
                        <div className={style.imgtitle}><img src={require("../img/aboutus_title.png")} alt="" className={style.aboutus_title} /></div>
                        <p className={style.contents}>从2002年初在温江柳春路开出第一家店 至今全国七婆店门数量已达1100多家 涵盖全国各省、市、自治区，并全面开启海外渠道</p>
                        <p className={style.more}>
                            <Link to={{ pathname: "/aboutus.html" }}>
                                查看更多
                           </Link>
                        </p>
                        <div className={style.imgtwo}>
                            <video className={style.img_aboutus} muted="muted" controls="controls" object-fit="fill" preload="auto" autoplay = {true}>
                                <source src="http://www.qpccx.com/videos.mp4" type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                </div>

                {/* 菜品展示轮播 */}
                <div className={style.menushow}>
                    <img src={require("../img/menu_title.png")} className={style.imgtitle} alt="" />
                    <div className={["swiper-container", style.mycenter_two].join(" ")} id="menu_show">
                        <div className="swiper-wrapper">

                            {
                                this.state.product.map((item, ind) => {
                                    return <div className={["swiper-slide", style.menu_item].join(" ")} key={ind}>
                                        <div className={style.menu_img}>
                                            <img src={`http://www.qpccx.com:8877${item.img}`} alt="" className={style.menu_imgone} />
                                        </div>
                                        <h5>{item.name}</h5>
                                        <h5>{item.en_name}</h5>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                {/* 新闻资讯 */}
                <div className={style.news}>
                    <div className="center">
                        <img src={require("../img/news_title.png")} alt="" className={style.imgtitle} />
                        <div className={style.newsone}>
                            <div className={style.newsimg}>
                                <img src={`http://www.qpccx.com:8877${this.state.news.image}`} alt="" className={style.newsimgone} />
                            </div>
                            <h4>{this.state.news.title}</h4>
                            <p>{this.state.news.remark}</p>
                            <div className={style.more}>
                                <Link to={{ pathname: "/news.html" }}>
                                    more
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* // 门店展示 */}
                <div className={style.shopshow}>
                    <img src={require("../img/shoptitle.png")} alt="" className={style.imgtitle} />
                    <div className={style.shop_pic}>
                        <Lazyimg
                            className="lazy"
                            src={require("../img/shop_pic.png")}
                        />
                    </div>
                    <div className={style.more}>
                        <Link to={{ pathname: "/shopshow.html" }}>
                            查看更多
                        </Link>
                    </div>
                </div>

                {/* 合作加盟 */}
                <div className={style.operation}>
                    <div className="center">
                        <div className={style.top}>
                            <img src={require("../img/work_title.png")} alt="" className={style.coo_title} />
                            <p>
                                经过多年的发展，成都七婆已经成为一家集品牌管理、餐饮策划、经营诊断，火锅底料生产、人资输出、物流配套，为一体的综合性餐饮企业，旗下子品牌达17个无论您身在何处，七婆总能为您提供一款适合的产品
                            </p>
                            <div className={style.more}>   <Link to={{ pathname: "/cooperation.html" }}>查看更多 </Link></div>
                        </div>
                        <div className={style.bottomm}>
                            <div className={["swiper-container", style.fivehot].join(" ")} id="five_hot" >
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <img src={require("../img/hezuo01.jpg")} alt="" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={require("../img/hezuo02.jpg")} alt="" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={require("../img/hezuo03.jpg")} alt="" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={require("../img/hezuo04.jpg")} alt="" />
                                    </div>
                                    <div className="swiper-slide">
                                        <img src={require("../img/hezuo05.jpg")} alt="" />
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <BottomnavM />
            </div>
        );
    }
}

export default Home;