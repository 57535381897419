import axios from "axios";
axios.defaults.baseURL = "http://www.qpccx.com:8877";
// axios.defaults.withCredentials = true;
// axios.defaults.timeout = 5000;
// axios.defaults.headers = {'content-type':'application/json;charset="UTF-8"'};
axios.defaults.headers.post = {'Content-Type': 'application/x-www-form-urlencoded'};
axios.defaults.headers.get = {'content-type':'application/json;charset="UTF-8"'};
axios.defaults.headers.post['Content-Type'] = 'application/json'; //配置请求头

export default class Axios {
    static get(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, params)
                .then(res => {
                    if (res.status == '200') {
                        resolve(res);
                    } else {
                        console.log(res)
                        reject(res);
                    }
                })
                .catch(err => {
                    reject(err);    
                })
        });

    }

    static post(url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, params)
                .then(res => {
                    if (res.status == '200') {
                        resolve(res);
                    } else {
                        console.log(res)
                        reject(res);
                    }
                })
                .catch(err => {
                    reject(err);
                })
        });
    }
}