import React, { Component } from 'react';
import style from "./Bottomnav.module.css";
import { Link } from 'react-router-dom';
import axios from "../../model/Axios";
class Bottomnav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: {}
        }
    }
    componentDidMount() {
        axios.get('/home/index')
            .then((res) => {
                let data = res.data.res;
                this.setState({
                    config: data.config,
                })
                // console.log(this.state.config);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    doSubmit = () => {
        if (this.refs.my_name.value === null || this.refs.my_name.value === "") {
            alert("请输入您的名字");
            return false;
        } else if (this.refs.my_tel.value === null || this.refs.my_tel.value === "") {
            alert("请输入您的电话");
            return false;
        } else if (this.refs.my_words.value === null || this.refs.my_words.value === "") {
            alert("请输入您的留言");
            return false;
        }

        //手机正则
        let mobilePtn = /^1[34578][0-9]{9}$/;
        //座机正则
        let landlinePtn = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
        if (this.refs.my_tel.value.substring(0, 1) === "0") {
            if (!landlinePtn.test(this.refs.my_tel.value)) {
                alert("您输入的座机号码不正确，请重新输入");
                return false;
            }
        } else {
            if (!mobilePtn.test(this.refs.my_tel.value)) {
                alert("您输入的手机号码不正确，请重新输入");
                return false;
            }
        }

        axios.post('/home/index/information', { name: this.refs.my_name.value, tel: this.refs.my_tel.value, content: this.refs.my_words.value })
            .then((res) => {
                if (res.data.msg == "success")
                    alert("提交成功");
            })
            .catch((err) => {
                console.log(err);
            })

    }

    render() {
        return (
            <div className={style.bottomnav}>
                <div className={style.center} >
                    <div className={style.left} data-aos="zoom-in" >
                        <p data-aos="zoom-in">联系我们</p>
                        <input placeholder="姓名" ref="my_name" />
                        <input placeholder="电话" ref="my_tel" />
                        <textarea placeholder="留言" ref="my_words"></textarea>
                        <button onClick={this.doSubmit} ref="my_subimit" >提交</button>
                    </div>
                    <div className={style.right} >
                        <ul className={style.navinfo} style={{ minWidth: "559px" }}>
                            <li>
                                <Link to={{ pathname: "/aboutus.html" }} data-aos="zoom-in">关于我们</Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "/menushow.html" }} data-aos="zoom-in">七婆美食</Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "/shopshow.html" }} data-aos="zoom-in">店面展示</Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "/news.html" }} data-aos="zoom-in">新闻中心</Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "/cooperation.html" }} data-aos="zoom-in">招商加盟</Link>
                            </li>
                            <li>
                                <Link to={{ pathname: "/contactus.html" }} data-aos="zoom-in">联系我们</Link>
                            </li>
                        </ul>
                        <div className={style.botttt}>
                            <div className={style.shopinfo}>
                                <div data-aos="zoom-in">
                                    <img src={require("./img/t.png")} alt="" className={style.toppoint} /><br />
                                    <span >招商加盟</span><br />
                                    <span >加盟 QQ：{this.state.config.qq}</span><br />
                                    <span>加盟热线：{this.state.config.phone}</span><br />
                                    <span className = {style.phoneNum}>15520831111</span>
                                    <span className = {style.phoneNum}>17358974307</span>
                                    {/* <span className = {style.phoneNum}></span> */}
                                    <img src={require("./img/b.png")} alt="" className={style.toppoint} />
                                </div>
                                <div data-aos="zoom-in" className={style.shopinfotwo}>
                                    <img src={require("./img/t.png")} alt="" className={style.toppoint} /><br />
                                    <span  className = {style.comadd}>公司地址</span>
                                    <span className = {style.comaddDetail}>{this.state.config.address}</span><br />
                                    <span > <a href = "http://beian.miit.gov.cn/" data-aos="zoom-in">备案号：蜀ICP备09010789号</a></span><br />
                                    {/* <span>府路639号</span><br /> */}
                                    <img src={require("./img/b.png")} alt="" className={[style.toppoint,style.toppoints].join(" ")} />
                                </div>
                                <img src={require("./img/ip10@2x.png")} className={style.wechart} alt="" data-aos="zoom-in" />

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Bottomnav;