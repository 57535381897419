import React, { Component } from 'react';
import TopnavM from "../../components/TopnavM/TopnavM.js";
import BottomnavM from "../../components/BottomnavM/BottomnavM";
import style from "./Newsdetail.module.css";
// import { Link } from 'react-router-dom';
import Lazyimg from 'react-lazyimg-component';
import Axios from '../../model/Axios';

class Newsdetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newinfo: {},
            news: [],
            content: "",
        };
    }
    componentDidMount() {
        // 历史存储数据
        let myid;
        if (this.props.location.state) {//判断当前有参数
            myid = this.props.location.state.id;
            sessionStorage.setItem('data', myid);// 存入到sessionStorage中
        } else {
            myid = sessionStorage.getItem('data');// 当state没有参数时，取sessionStorage中的参数
        }
        this.setState({
            myid
        })

        Axios.post("/home/news/details", { id: myid })
            .then((res) => {
                let data = res.data.new;
                this.setState({
                    newinfo: data,
                    content: data.content,
                })

            })
            .catch((err) => {
                console.log(err);
            })

        // 随机展示四条不重复推荐数据
        Axios.post("/home/news")
            .then((res) => {
                let data = res.data.news;
                let newData = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].id !== myid) {
                        newData.push(data[i]);
                    }
                }
                let showData = [];
                if (newData.length <= 4) {
                    for (let i = 0; i < newData.length; i++) {
                        showData.push(newData[i]);
                    }
                } else {
                    for (let i = 0; i < 4; i++) {
                        let ind = parseInt(Math.random() * newData.length)
                        showData.push(newData[ind]);
                        newData.splice(ind, 1);
                    }
                }
                this.setState({
                    news: showData
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }
    hangleClick = (ev) => {
        let myid = ev.currentTarget.getAttribute("data-index");
        // console.log("myid"+myid);
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        Axios.post("/home/news/details", { id: myid})
            .then((res) => {
                let data = res.data.new;
                this.setState({
                    newinfo: data,
                    content: data.content
                })

            })
            .catch((err) => {
                console.log(err);
            })
    }

    render() {
        return (
            <div>
                <TopnavM />
                <div className="center">
                    <div className={style.news_detail}>
                        <h2>{this.state.newinfo.title}</h2>
                        <p className={style.news_date}>{this.state.newinfo.create_time}</p>
                        <div className={style.news_info}>
                            <div className={style.news_img} dangerouslySetInnerHTML={{ __html: this.state.content }}>
                            </div>
                            <p>{this.state.newinfo.remark}</p>
                        </div>
                    </div>
                    <div className={style.news_advise}>
                        <span>相关推荐</span>
                        <div className={style.news_advise_detail}>
                            {
                                this.state.news.length > 0 && this.state.news.map((item, ind) => {
                                    return <div className={style.news_advise_one} key={ind} onClick={this.hangleClick} data-index={item.id}>
                                        <div className={style.news_advise_img}>
                                            <Lazyimg src={`http://www.qpccx.com:8877${item.image}`} className="lazy"/>
                                        </div>
                                        <h >{item.title}</h>
                                        <p >{item.remark}</p>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                </div>
                <BottomnavM />
            </div>
        );
    }
}

export default Newsdetail;