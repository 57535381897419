import React, { Component } from 'react';
import TopnavM from "../../components/TopnavM/TopnavM.js";
import BottomnavM from "../../components/BottomnavM/BottomnavM";
import style from "./Menushow.module.css";
import Lazyimg from 'react-lazyimg-component';
import Axios from '../../model/Axios';

class Menushow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgc: "",
            pdcate: [],
            product: [],
            isshow_proD: "none",
            menuD: ""
        };
    }
    componentDidMount() {

        Axios.get("/home/product")
            .then((res) => {
                let data = res.data.res;
                this.setState({
                    pdcate: data.pdcate,
                    product: data.product
                })
                this.addBox();
                // console.log(this.state.product);
            })
            .catch((err) => {
                console.log(err)
            })
    }
    clickMenu = (ev) => {
        // console(ev.target);
        console.log(ev.currentTarget.getAttribute("data-ind"));
        let myid=ev.currentTarget.getAttribute("data-ind")

        let Tag_name = ev.target.parentElement.parentElement.tagName;
        this.changeColor();
        if (Tag_name === "DIV") {
            ev.target.style.backgroundColor = "#CC0E17";
            ev.target.style.color = "white";
            ev.target.style.borderColor = "transparent";
            Axios.post("/home/product", { id:  myid })
                .then((res) => {
                    let data = res.data.res;
                    this.setState({
                        product: data.product
                    })
                })
                .catch(err => {
                    console.log(err)
                }
                )
        } else if (Tag_name === "LI") {
            ev.target.parentElement.parentElement.style.backgroundColor = "#CC0E17";
            ev.target.parentElement.parentElement.style.color = "white";
            ev.target.parentElement.parentElement.style.borderColor = "transparent";
            Axios.post("/home/product", { id:  myid })
                .then((res) => {
                    let data = res.data.res;
                    this.setState({
                        product: data.product
                    })
                })
                .catch(err => {
                    console.log(err)
                }
                )
        }
    }
    changeColor = () => {
        for (let i = 0; i < this.refs.menuNav.children.length; i++) {
            this.refs.menuNav.children[i].style.backgroundColor = "";
            this.refs.menuNav.children[i].style.color = "#797979";
            this.refs.menuNav.children[i].style.borderColor = "#E6E6E6";
        }
    }
    // 打开和关闭菜单

    openMenu = (ev) => {
        let myid=ev.currentTarget.getAttribute("data-in")
        this.setState({
            isshow_proD: "flex"
        })
        // console.log(ev.target.id)
        Axios.post("/home/product/show", { id: myid })
            .then(res => {
                let data = res.data.res;
                // console.log(data)
                this.setState({
                    menuD: data.product
                })
                // console.log(this.state.menuD)
            })
            .catch(err => {
                console.log(err)
            })
    }
    closeMenu = () => {
        this.setState({
            isshow_proD: "none"
        })

    }
    render() {
        return (
            <div>
                <TopnavM />
                <div className={style.menuTop}>
                    <img src={require("../img/menutop.png")} alt="" />
                </div>
                <div className="center">
                    <div className={style.mainmenu}>
                        <ul className={style.menuNav} ref="menuNav"  >
                            {
                                this.state.pdcate.map((item, ind) => {
                                    return <li key={ind} data-ind={item.id} onClick={this.clickMenu} style={{ backgroundColor: this.state.bgc }} className={style.menuNavItem}>{item.name}</li>
                                })
                            }
                            {/* <li className={style.menuNavItem} style={{borderColor:"transparent"}}></li> */}
                        </ul>
                        <div className={style.menuInfo} ref="menuInfo">
                            {/* 遍历菜品 */}
                            {
                                this.state.product.map((item, index) => {
                                    // console.log(item.id)
                                    return <div className={style.menuOne} key={index} onClick={this.openMenu} data-in={item.id}>
                                        <div className={style.menuinfoss}>
                                            <div className={style.menuImg}>
                                                <Lazyimg
                                                    className="lazy"
                                                    src={`http://www.qpccx.com:8877${item.image}`}
                                                    js_effect="transition.shrinkIn"
                                                   />
                                            </div>
                                            <h5 className={style.tileName} >{item.name}</h5>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        {/* 菜单详情弹出层 */}
                        <div className={style.pro_detail} ref="prodetail" style={{ display: this.state.isshow_proD }}>
                            <div className={style.menuinfoss}>
                                <div className={style.menu_close} onClick={this.closeMenu}>×</div>
                                <div className={style.menuImg}>
                                    <img
                                        src={`http://www.qpccx.com:8877${this.state.menuD.image}`}
                                        js_effect="transition.shrinkIn" alt=""
                                    />
                                </div>
                                <h5 className={style.tileName}>{this.state.menuD.name}</h5>
                            </div>
                        </div>

                    </div>

                </div>
                <BottomnavM />
            </div>
        );
    }
}

export default Menushow;